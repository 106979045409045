import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../../fetch/generalFailedPC';
import Tooltip from '../../general/Tooltip';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import {Department} from '../../../constants/propTypesDefinitions';
import {createFetchDepartmentRemove} from '../../../backend/apiCalls';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class DepartmentRemove extends React.PureComponent {
    static propTypes = {
        reload: PropTypes.func.isRequired,
        department: Department.isRequired,
    };

    render() {
        const {department = {}, reload} = this.props;
        return <FormModal
            openNode={
                <Tooltip title={<Trans>Odstránenie oddelenia</Trans>}>
                    <Button className="mx-1" size="small" type="danger" icon="delete"/>
                </Tooltip>
            }
            values={{}}
            onFinishSuccessful={reload}
            title={<Trans>Odstránenie oddelenia</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Chcete odstrániť oddelenie?</Trans>,
                createFetchDepartmentRemove(department.id),
            )}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa odstrániť oddelenie.`)}
        />;
    }

}

export default DepartmentRemove;